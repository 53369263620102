/**
 * @flow
 * @relayHash fd3e7c1543b8c423b9758461b6f42ac5
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type PaymentTypeTypeEnum = "BANK" | "CASH" | "OTHER" | "%future added value";
export type PaymentTypeContextQueryVariables = {||};
export type PaymentTypeContextQueryResponse = {|
  +paymentTypes: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +code: number,
        +type: PaymentTypeTypeEnum,
        +company: ?{|
          +name: string
        |},
      |}
    |}>
  |}
|};
export type PaymentTypeContextQuery = {|
  variables: PaymentTypeContextQueryVariables,
  response: PaymentTypeContextQueryResponse,
|};
*/


/*
query PaymentTypeContextQuery {
  paymentTypes(orderBy: [code], order: ASC, where: {isDisabled: {eq: "false"}}) {
    edges {
      node {
        id
        name
        code
        type
        company {
          name
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "order",
    "value": "ASC"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": [
      "code"
    ]
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "isDisabled": {
        "eq": "false"
      }
    }
  }
],
v1 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "id",
  "args": null,
  "storageKey": null
},
v2 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "name",
  "args": null,
  "storageKey": null
},
v3 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "code",
  "args": null,
  "storageKey": null
},
v4 = {
  "kind": "ScalarField",
  "alias": null,
  "name": "type",
  "args": null,
  "storageKey": null
};
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "PaymentTypeContextQuery",
    "type": "Query",
    "metadata": null,
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "paymentTypes",
        "storageKey": "paymentTypes(order:\"ASC\",orderBy:[\"code\"],where:{\"isDisabled\":{\"eq\":\"false\"}})",
        "args": (v0/*: any*/),
        "concreteType": "PaymentTypeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "PaymentTypeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "PaymentType",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "company",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "operation": {
    "kind": "Operation",
    "name": "PaymentTypeContextQuery",
    "argumentDefinitions": [],
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "paymentTypes",
        "storageKey": "paymentTypes(order:\"ASC\",orderBy:[\"code\"],where:{\"isDisabled\":{\"eq\":\"false\"}})",
        "args": (v0/*: any*/),
        "concreteType": "PaymentTypeConnection",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "alias": null,
            "name": "edges",
            "storageKey": null,
            "args": null,
            "concreteType": "PaymentTypeEdge",
            "plural": true,
            "selections": [
              {
                "kind": "LinkedField",
                "alias": null,
                "name": "node",
                "storageKey": null,
                "args": null,
                "concreteType": "PaymentType",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "kind": "LinkedField",
                    "alias": null,
                    "name": "company",
                    "storageKey": null,
                    "args": null,
                    "concreteType": "Company",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      (v1/*: any*/)
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "operationKind": "query",
    "name": "PaymentTypeContextQuery",
    "id": null,
    "text": "query PaymentTypeContextQuery {\n  paymentTypes(orderBy: [code], order: ASC, where: {isDisabled: {eq: \"false\"}}) {\n    edges {\n      node {\n        id\n        name\n        code\n        type\n        company {\n          name\n          id\n        }\n      }\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '4c20e51b25ae9f7318d00f27e4cbb9dd';
module.exports = node;
