/**
 * @flow
 * @relayHash 01dc087458f0e5d62036de212970b8b0
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ProductContextQueryVariables = {||};
export type ProductContextQueryResponse = {|
  +products: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
      |}
    |}>
  |}
|};
export type ProductContextQuery = {|
  variables: ProductContextQueryVariables,
  response: ProductContextQueryResponse,
|};
*/

/*
query ProductContextQuery {
  products(orderBy: [code], order: ASC) {
    edges {
      node {
        id
        name
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
    {
      kind: 'LinkedField',
      alias: null,
      name: 'products',
      storageKey: 'products(order:"ASC",orderBy:["code"])',
      args: [
        {
          kind: 'Literal',
          name: 'order',
          value: 'ASC',
        },
        {
          kind: 'Literal',
          name: 'orderBy',
          value: ['code'],
        },
      ],
      concreteType: 'ProductConnection',
      plural: false,
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'edges',
          storageKey: null,
          args: null,
          concreteType: 'ProductEdge',
          plural: true,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'node',
              storageKey: null,
              args: null,
              concreteType: 'Product',
              plural: false,
              selections: [
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'id',
                  args: null,
                  storageKey: null,
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'name',
                  args: null,
                  storageKey: null,
                },
              ],
            },
          ],
        },
      ],
    },
  ]
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'ProductContextQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: [],
      selections: (v0 /*: any*/),
    },
    operation: {
      kind: 'Operation',
      name: 'ProductContextQuery',
      argumentDefinitions: [],
      selections: (v0 /*: any*/),
    },
    params: {
      operationKind: 'query',
      name: 'ProductContextQuery',
      id: null,
      text:
        'query ProductContextQuery {\n  products(orderBy: [code], order: ASC) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n',
      metadata: {},
    },
  }
})()
// prettier-ignore
;(node/*: any*/).hash = 'dd044f201d5c4b6dbc14042e1f76db5d';
module.exports = node
