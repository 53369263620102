/**
 * @flow
 * @relayHash bf7c292137f57746529dc9e0724c9536
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClientDocumentEnum = "DPI" | "PASAPORTE" | "%future added value";
export type ClientCreateInput = {|
  name: string,
  companyId: string,
  locationId: string,
  collectorId: string,
  taxName: string,
  taxUUID: string,
  taxAddress?: ?string,
  uuid?: ?string,
  phone?: ?string,
  cellPhone?: ?string,
  email?: ?string,
  address: string,
  notes?: ?string,
  isCredit?: ?boolean,
  daysCredit?: ?number,
  documentType?: ?ClientDocumentEnum,
  clientMutationId?: ?string,
|};
export type ClientCreateModalMutationVariables = {|
  input: ClientCreateInput
|};
export type ClientCreateModalMutationResponse = {|
  +createClient: ?{|
    +output: ?{|
      +id: string
    |}
  |}
|};
export type ClientCreateModalMutation = {|
  variables: ClientCreateModalMutationVariables,
  response: ClientCreateModalMutationResponse,
|};
*/


/*
mutation ClientCreateModalMutation(
  $input: ClientCreateInput!
) {
  createClient(input: $input) {
    output {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "ClientCreateInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "createClient",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "ClientCreatePayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "output",
        "storageKey": null,
        "args": null,
        "concreteType": "Client",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "ClientCreateModalMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "ClientCreateModalMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "ClientCreateModalMutation",
    "id": null,
    "text": "mutation ClientCreateModalMutation(\n  $input: ClientCreateInput!\n) {\n  createClient(input: $input) {\n    output {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ac161a7005617fdaa8f3fa1399b4ca63';
module.exports = node;
