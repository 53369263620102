import React from 'react'
import { Form as UIForm } from 'semantic-ui-react'

export default function Form(props) {
  return (
    <UIForm
      loading={props.loading}
      widths={props.widths}
      onSubmit={props.onSubmit}
    >
      {props.children}
    </UIForm>
  )
}

Form.defaultProps = {
  loading: false,
  widths: 'equal',
  onSubmit: () => {},
}

Form.Row = props => <UIForm.Group widths='equal'>{props.children}</UIForm.Group>
