import alert from 'config/alert'
import SessionContext from 'context/SessionContext'
import React, { useContext, useState } from 'react'
import { Button, Form, Grid, Header, Segment } from 'semantic-ui-react'
import Page from './Page'

export default function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const session = useContext(SessionContext)

  function login() {
    setLoading(true)
    session.login({
      email,
      password,
      onError: error => {
        setLoading(false)
        alert.error(error.message)
      },
    })
  }

  return (
    <Page>
      <Grid textAlign='center' verticalAlign='middle'>
        <Grid.Column style={{ maxWidth: 450 }}>
          <Header
            content='Iniciar Sesión'
            as='h2'
            color='teal'
            textAlign='center'
          />

          <Form size='large' loading={loading}>
            <Segment stacked>
              <Form.Input
                fluid
                icon='user'
                iconPosition='left'
                placeholder='Email'
                value={email}
                onChange={e => setEmail(e.target.value)}
              />

              <Form.Input
                fluid
                icon='lock'
                iconPosition='left'
                placeholder='Contraseña'
                type='password'
                value={password}
                onChange={e => setPassword(e.target.value)}
              />

              <Button
                fluid
                content='Ingresar'
                color='teal'
                size='large'
                onClick={login}
              />
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </Page>
  )
}
