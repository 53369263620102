import graphql from 'babel-plugin-relay/macro'
import Form from 'components/ui/Form'
import Modal from 'components/ui/Modal'
import TextInput from 'components/ui/TextInput'
import { commitMutation } from 'config/amber-relay'
// Context
import ModalContext from 'context/ModalContext'
import { LocationSelect } from 'core/location'
// Hooks
import useForm from 'hooks/useForm'
import React from 'react'
// Components
import { Button } from 'semantic-ui-react'

export default function ClientCreateModal() {
  const {
    values,
    errors,
    touched,
    reset,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useForm({
    validations: {
      name: { required: true },
      taxUUID: { required: true },
      address: { required: true },
      locationId: { required: true },
    },
  })

  const { isClientCreateModalOpen, closeClientCreateModal } = React.useContext(
    ModalContext
  )
  const [loading, setLoading] = React.useState(false)

  const onClose = () => {
    if (loading) return

    reset(closeClientCreateModal)
  }

  const onSubmit = () => {
    setLoading(true)

    commitMutation({
      mutation: graphql`
        mutation ClientCreateModalMutation($input: ClientCreateInput!) {
          createClient(input: $input) {
            output {
              id
            }
          }
        }
      `,
      variables: {
        input: {
          ...values,
          taxName: values.name,
          taxAddress: values.address,
        },
      },
    })
      .then(_ => reset(closeClientCreateModal))
      .catch(err => alert.error(err.message))
      .finally(_ => setLoading(false))
  }

  return (
    <Modal
      header='Crear Cliente'
      size='small'
      open={isClientCreateModalOpen}
      onClose={onClose}
    >
      <Form loading={loading} onSubmit={handleSubmit(onSubmit)}>
        <Form.Row>
          <TextInput
            label='Nombre'
            name='name'
            value={values.name}
            error={errors.name}
            touched={touched.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <TextInput
            label='Teléfono'
            name='phone'
            value={values.phone}
            error={errors.phone}
            touched={touched.phone}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Row>

        <Form.Row>
          {/* TODO fixme*/}
          <LocationSelect
            value={values.locationId}
            error={errors.locationId}
            touched={touched.locationId}
            onChange={value => handleChange({ name: 'locationId', value })}
            onBlur={handleBlur}
          />

          <TextInput
            label='Dirección'
            name='address'
            value={values.address}
            error={errors.address}
            touched={touched.address}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Row>

        <Form.Row>
          <TextInput
            label='NIT'
            name='taxUUID'
            value={values.taxUUID}
            error={errors.taxUUID}
            touched={touched.taxUUID}
            onChange={handleChange}
            onBlur={handleBlur}
          />

          <TextInput
            label='DPI'
            name='uuid'
            value={values.uuid}
            error={errors.uuid}
            touched={touched.uuid}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Row>

        <Button color='blue' content='Guardar' type='submit' />
      </Form>
    </Modal>
  )
}
