import MenuItemCreate from 'components/MenuItemCreate'
import SessionContext from 'context/SessionContext'
import SidebarContext from 'context/SidebarContext'
import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, Icon, Menu } from 'semantic-ui-react'

export default function Navbar(props) {
  const { setOpen } = useContext(SidebarContext)
  const { user, logout } = useContext(SessionContext)
  const history = useHistory()

  const confirmLogout = () => {
    alert.confirm('¿está seguro que desea cerrar sesión?').then(res => {
      if (res) {
        logout()
      }
    })
  }

  return (
    <Menu id='amber-navbar' style={{ marginBottom: '0px' }}>
      <Menu.Item onClick={setOpen}>
        <Icon name='bars' />
      </Menu.Item>

      <Menu.Menu position='right'>
        <MenuItemCreate />

        <Menu.Item>
          <Dropdown
            trigger={
              <span>
                <Icon name='user' /> {user ? user.name : 'Usuario'}
              </span>
            }
          >
            <Dropdown.Menu>
              <Dropdown.Item text='Perfil' onClick={_ => history.push(`/me`)} />
              <Dropdown.Item text='Cerrar Sesión' onClick={confirmLogout} />
            </Dropdown.Menu>
          </Dropdown>
        </Menu.Item>
      </Menu.Menu>
    </Menu>
  )
}
