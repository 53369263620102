import graphql from 'babel-plugin-relay/macro'
import Form from 'components/ui/Form'
import Modal from 'components/ui/Modal'
import TextArea from 'components/ui/TextArea'
import TextInput from 'components/ui/TextInput'
import { commitMutation } from 'config/amber-relay'
// Context
import ModalContext from 'context/ModalContext'
// Hooks
import useForm from 'hooks/useForm'
import React from 'react'
import { useHistory } from 'react-router-dom'
// Components
import { Button } from 'semantic-ui-react'

export default function RoleCreateModal() {
  const {
    values,
    errors,
    touched,
    reset,
    handleSubmit,
    handleChange,
    handleBlur,
  } = useForm({ validations: { name: { required: true } } })
  const { isRoleCreateModalOpen, closeRoleCreateModal } = React.useContext(
    ModalContext
  )
  const [loading, setLoading] = React.useState(false)
  const history = useHistory()

  const onClose = () => {
    if (loading) return

    reset(closeRoleCreateModal)
  }

  const onSubmit = () => {
    setLoading(true)

    commitMutation({
      mutation: graphql`
        mutation RoleCreateModalMutation($input: RoleCreateInput!) {
          createRole(input: $input) {
            output {
              id
            }
          }
        }
      `,
      variables: { input: { ...values, permissions: [] } },
    })
      .then(({ createRole }) => {
        history.push(`/role/update/${createRole.output.id}`)
        reset(closeRoleCreateModal)
      })
      .catch(err => alert.error(err.message))
      .finally(_ => setLoading(false))
  }

  return (
    <Modal
      header='Crear Rol'
      size='mini'
      open={isRoleCreateModalOpen}
      onClose={onClose}
    >
      <Form loading={loading} onSubmit={handleSubmit(onSubmit)}>
        <TextInput
          label='Nombre'
          name='name'
          value={values.name}
          error={errors.name}
          touched={touched.name}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <TextArea
          label='Descripción'
          name='description'
          value={values.description}
          error={errors.description}
          touched={touched.description}
          onChange={handleChange}
          onBlur={handleBlur}
        />

        <Button fluid color='blue' content='Guardar' type='submit' />
      </Form>
    </Modal>
  )
}
