import React from 'react'
import { Modal as UIModal } from 'semantic-ui-react'

export default function Modal(props) {
  return (
    <UIModal
      centered={props.centered}
      closeIcon={props.closeIcon}
      closeOnEscape={props.closeOnEscape}
      closeOnDimmerClick={props.closeOnDimmerClick}
      open={props.open}
      size={props.size}
      onClose={props.onClose}
    >
      {props.header && <UIModal.Header content={props.header} />}

      <UIModal.Content>{props.children}</UIModal.Content>

      {props.actions && <UIModal.Actions>{props.actions}</UIModal.Actions>}
    </UIModal>
  )
}

Modal.defaultProps = {
  centered: false,
  closeIcon: true,
  closeOnEscape: true,
  closeOnDimmerClick: true,
  open: false,
  size: 'small',
  onClose: () => {},
}
