import React from 'react'

export const SidebarContext = React.createContext()

export const SidebarConsumer = SidebarContext.Consumer

const SIDEBAR_KEY = 'amber-sidebar'

export class SidebarProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isOpen: localStorage.getItem(SIDEBAR_KEY) !== 'false',
    }
  }

  setOpen = _ =>
    this.setState(
      ({ isOpen }) => ({ isOpen: !isOpen }),
      () => localStorage.setItem(SIDEBAR_KEY, this.state.isOpen)
    )

  render() {
    return (
      <SidebarContext.Provider
        value={{
          ...this.state,
          setOpen: this.setOpen,
        }}
      >
        {this.props.children}
      </SidebarContext.Provider>
    )
  }
}

export default SidebarContext
