/**
 * @flow
 * @relayHash f091ca18f5117cd7a487acb981001e26
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LocationNameQueryVariables = {|
  id: string
|};
export type LocationNameQueryResponse = {|
  +location: ?{|
    +name: string
  |}
|};
export type LocationNameQuery = {|
  variables: LocationNameQueryVariables,
  response: LocationNameQueryResponse,
|};
*/

/*
query LocationNameQuery(
  $id: ID!
) {
  location(id: $id) {
    name
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null,
    }
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'LocationNameQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'location',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'Location',
          plural: false,
          selections: [(v2 /*: any*/)],
        },
      ],
    },
    operation: {
      kind: 'Operation',
      name: 'LocationNameQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'location',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'Location',
          plural: false,
          selections: [
            (v2 /*: any*/),
            {
              kind: 'ScalarField',
              alias: null,
              name: 'id',
              args: null,
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      operationKind: 'query',
      name: 'LocationNameQuery',
      id: null,
      text:
        'query LocationNameQuery(\n  $id: ID!\n) {\n  location(id: $id) {\n    name\n    id\n  }\n}\n',
      metadata: {},
    },
  }
})()
// prettier-ignore
;(node/*: any*/).hash = 'e8c308389d4312e8e7f8f6a1f167d7c9';
module.exports = node
