import React from 'react'
import { Form as UIForm } from 'semantic-ui-react'

// Hooks
import useInput from 'hooks/useInput'

export default function TextInput(props) {
  const { handleBlur, handleChange } = useInput(props)

  return (
    <UIForm.Input
      disabled={props.disabled}
      error={props.touched && props.error}
      label={props.label}
      loading={props.loading}
      name={props.name}
      placeholder={props.placeholder || props.label}
      required={props.required}
      transparent={props.transparent}
      type={props.type}
      value={props.value || ''}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  )
}

TextInput.defaultProps = {
  disabled: false,
  error: false,
  label: null,
  loading: false,
  name: null,
  placeholder: null,
  required: false,
  touched: false,
  transparent: false,
  type: 'text',
  value: null,
  onBlur: () => {},
  onChange: () => {},
}
