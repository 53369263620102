import Api from './api'
import { commitMutation } from 'config/amber-relay'
import graphql from 'babel-plugin-relay/macro'

const login = (email, password) => Api.POST('/login', { email, password })

/**
 * 
 * @param {*} sessionId 
 * @param {*} userId 
 */
const logout  = ( sessionId, userId ) => {
  commitMutation({
    mutation:graphql`
      mutation AuthDisableSessionMutation($input: UserDisableSessionInput!  ){
        disableSessions(input: $input){
          output {
              id
            }
        }
      }
    `,
    variables:{
      input:{
        id: userId,
        clientMutationId: userId

      }
      
    }
  })
}

export default {
  login,logout
}
