import React from 'react'
import { withRouter } from 'react-router-dom'
import { Icon } from 'semantic-ui-react'

import SessionContext from 'context/SessionContext'

function SidebarItem(props) {
  const session = React.useContext(SessionContext)

  if (!props.can || !session.can(props.can)) return null

  const handleClick = e => {
    e.preventDefault()
    props.clearSearch()
    history.push(props.path)
  }

  const { history, location } = props

  const isActiveRoute = path => {
    if (!location) return false

    return (
      path === location.pathname || location.pathname.indexOf(`${path}/`) > -1
    )
  }

  return (
    <div
      className={`amber-sidebar-item ${isActiveRoute(props.path) ? 'active' : ''
        }`}
      onClick={handleClick}
    >

      <span>
        <Icon name={props.icon} style={{ marginRight: '8px' }} />
        {props.label}
      </span>
    </div>
  )
}

export default withRouter(SidebarItem)
