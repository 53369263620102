import SidebarContext from 'context/SidebarContext'
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { items } from '../config/menuItems'
import SearchBar from './SearchBar'
import SidebarItem from './SidebarItem'

function Sidebar() {
  const { isOpen } = React.useContext(SidebarContext)
  const [search, setSearch] = useState('')

  const [filteredItems, setFilteredItems] = useState([])

  useEffect(() => {
    setFilteredItems(
      items.filter(filteredItems =>
        filteredItems.label.toLowerCase().includes(search.toLowerCase())
      )
    )
  }, [search])

  if (!isOpen) return null

  function clearSearch() {
    setSearch('')
  }

  return (
    <div
      style={{
        height: '100%',
        overflowY: 'scroll',
        overflowX: 'hidden',
        scrollbarWidth: '0px',
        backgroundColor: '#1849a0',
      }}
    >
      <div className='amber-sidebar'>
        <h1 style={{ padding: '16px', margin: '0' }}>Amber</h1>

        <SearchBar
          placeholder='Buscar...'
          value={search}
          onChange={setSearch}
        />

        {filteredItems.map((item, i) => (
          <SidebarItem {...item} key={i} clearSearch={clearSearch} />
        ))}
      </div>
    </div>
  )
}

export default withRouter(Sidebar)
