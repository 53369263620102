import React from 'react'
import { Form } from 'semantic-ui-react'

export default function SearchBar(props) {
  const input = (
    <input
      type={props.type}
      className={!props.filter ? 'input-search' : null}
      placeholder={props.placeholder}
      value={props.value || ''}
      onChange={event => props.onChange(event.target.value)}
    />
  )

  return props.filter ? (
    <Form.Field>
      <label>{props.title}</label>
      {input}
    </Form.Field>
  ) : (
    <div className='amber-sidebar-item'>{input}</div>
  )
}

SearchBar.defaultProps = {
  type: 'text',
  placeholder: null,
  filter: false,
}
