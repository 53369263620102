import Login from 'components/Login'
import Navbar from 'components/Navbar'
import Sidebar from 'components/Sidebar'
import { CompanyProvider } from 'context/CompanyContext'
import { ModalProvider } from 'context/ModalContext'
import { PaymentTypeProvider } from 'context/PaymentTypeContext'
import { ProductProvider } from 'context/ProductContext'
import { SessionConsumer, SessionProvider } from 'context/SessionContext'
import { SidebarProvider } from 'context/SidebarContext'
import { createBrowserHistory } from 'history'
import React from 'react'
import Relay from 'react-relay'
import { Route, Router, Switch } from 'react-router-dom'
import AppRouter from './AppRouter'

const history = createBrowserHistory()

export default function App() {
  return (
    <SessionProvider>
      <SessionConsumer>
        {({ isAuth }) => (
          <Router history={history} environment={Relay.Store}>
            {isAuth ? (
              <SidebarProvider>
                <ModalProvider>
                  <div className='amber-ui'>
                    {/* Menu */}
                    <Sidebar />

                    {/* Container */}
                    <div id='amber-content' className='amber-content'>
                      <Navbar />

                      <CompanyProvider>
                        <PaymentTypeProvider>
                          <ProductProvider>
                            <Switch>
                              <AppRouter />
                            </Switch>
                          </ProductProvider>
                        </PaymentTypeProvider>
                      </CompanyProvider>
                    </div>
                  </div>
                </ModalProvider>
              </SidebarProvider>
            ) : (
              <Route component={Login} />
            )}
          </Router>
        )}
      </SessionConsumer>
    </SessionProvider>
  )
}
