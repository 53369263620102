import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { fetchQuery } from 'config/amber-relay'
import Select from 'components/v2/Select'

export default function LocationSelect(props) {
  const getText = location => {
    return `${location.name}${
      location.location ? ` - ${getText(location.location)}` : ''
    }`
  }

  const fetch = async where => {
    const { locations } = await fetchQuery(
      graphql`
        query LocationSelectQuery($where: LocationWhere) {
          locations(where: $where, first: 10, orderBy: [name], order: ASC) {
            edges {
              node {
                id
                name
                location {
                  name
                  location {
                    name
                  }
                }
              }
            }
          }
        }
      `,
      { where }
    )

    return locations.edges.map(({ node }) => ({
      key: node.id,
      value: node.id,
      text: getText(node),
    }))
  }

  return (
    <Select
      {...props}
      getByValue={value => fetch({ id: { eq: value } })}
      loadOptions={searchQuery => fetch({ name: { iLike: `${searchQuery}%` } })}
    />
  )
}

LocationSelect.defaultProps = {
  name: 'locationId',
  label: 'Ubicación',
  placeholder: 'Ubicación',
  loadOnMount: true,
}
