import {
  READ_CLIENT,
  READ_COMPANY,
  READ_EXPENSE,
  READ_EXPENSE_TYPE,
  READ_INVOICE,
  READ_LOCATION,
  READ_MATERIAL_REQUEST,
  READ_MIKROTIK,
  READ_PAYMENT_ENTRY,
  READ_PAYMENT_TYPE,
  READ_PRODUCT,
  READ_PURCHASE,
  READ_PURCHASE_QUOTATION,
  READ_PURCHASE_QUOTATION_REQUEST,
  READ_REPORT,
  READ_ROLE,
  READ_SALE,
  READ_SESSION,
  READ_SUBSCRIPTION,
  READ_SUPPLIER,
  READ_TICKET,
  READ_TICKET_TYPE,
  READ_USER,
  READ_WAREHOUSE,
  READ_WAREHOUSE_ENTRY,
  VIEW_DASH_CORPORATIVO
} from './permissions'

const paths = [
  // ['Dashboard', '/dashboard', READ_DASHBOARD, 'chart line'],
  ['Reportes', '/report', READ_REPORT, 'chart line'],

  ['Clientes', '/client', READ_CLIENT, 'users'],
  ['Clientes Corporativos', '/clientCorporate', VIEW_DASH_CORPORATIVO, 'address card'],
  ['Suscripciones', '/subscription', READ_SUBSCRIPTION, 'calendar alternate'],
  ['Ventas', '/sale', READ_SALE, 'shopping cart'],
  ['Facturas', '/invoice', READ_INVOICE, 'file alternate'],
  ['Tickets', '/ticket', READ_TICKET, 'tasks'],
  ['Gastos', '/expense', READ_EXPENSE, 'id badge'],
  ['Transacciones', '/payment-entry', READ_PAYMENT_ENTRY, 'payment'],
  ['Inventario', '/warehouse-entry', READ_WAREHOUSE_ENTRY, 'payment'],

  ['Tipo de Tickets', '/ticket-type', READ_TICKET_TYPE, 'tasks'],
  ['Tipo de Gastos', '/expense-type', READ_EXPENSE_TYPE, 'id badge'],
  ['Caja y Bancos', '/payment-type', READ_PAYMENT_TYPE, 'university'],
  ['Bodegas', '/warehouse', READ_WAREHOUSE, 'university'],
  ['Proveedores', '/supplier', READ_SUPPLIER, 'boxes'],

  [
    'Solicitudes de material',
    '/material-request',
    READ_MATERIAL_REQUEST,
    'boxes',
  ],
  [
    'Solicitudes de cotización',
    '/purchase-quotation-request',
    READ_PURCHASE_QUOTATION_REQUEST,
    'boxes',
  ],
  ['Cotizaciones', '/purchase-quotation', READ_PURCHASE_QUOTATION, 'boxes'],
  ['Compras', '/purchase', READ_PURCHASE, 'boxes'],

  ['Empresas', '/company', READ_COMPANY, 'building'],
  ['Ubicaciones', '/location', READ_LOCATION, 'globe'],
  ['Productos', '/product', READ_PRODUCT, 'boxes'],
  ['Promociones', '/promotion', READ_SUBSCRIPTION, 'gift'],
  ['Mikrotiks', '/mikrotik', READ_MIKROTIK, 'hdd outline'],

  ['Roles', '/role', READ_ROLE, 'address card'],
  ['Usuarios', '/user', READ_USER, 'user'],
  ['Sesiones', '/session', READ_SESSION, 'desktop'],
]

export const items = paths.map(([label, path, can, icon]) => ({
  label,
  path,
  can,
  icon,
}))
