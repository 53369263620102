/**
 * @flow
 * @relayHash 7f21d8162c5db142beb4334c8edced31
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserDisableSessionInput = {|
  id: string,
  clientMutationId?: ?string,
|};
export type AuthDisableSessionMutationVariables = {|
  input: UserDisableSessionInput
|};
export type AuthDisableSessionMutationResponse = {|
  +disableSessions: ?{|
    +output: ?{|
      +id: string
    |}
  |}
|};
export type AuthDisableSessionMutation = {|
  variables: AuthDisableSessionMutationVariables,
  response: AuthDisableSessionMutationResponse,
|};
*/


/*
mutation AuthDisableSessionMutation(
  $input: UserDisableSessionInput!
) {
  disableSessions(input: $input) {
    output {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "LocalArgument",
    "name": "input",
    "type": "UserDisableSessionInput!",
    "defaultValue": null
  }
],
v1 = [
  {
    "kind": "LinkedField",
    "alias": null,
    "name": "disableSessions",
    "storageKey": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "UserDisableSessionPayload",
    "plural": false,
    "selections": [
      {
        "kind": "LinkedField",
        "alias": null,
        "name": "output",
        "storageKey": null,
        "args": null,
        "concreteType": "User",
        "plural": false,
        "selections": [
          {
            "kind": "ScalarField",
            "alias": null,
            "name": "id",
            "args": null,
            "storageKey": null
          }
        ]
      }
    ]
  }
];
return {
  "kind": "Request",
  "fragment": {
    "kind": "Fragment",
    "name": "AuthDisableSessionMutation",
    "type": "Mutation",
    "metadata": null,
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "operation": {
    "kind": "Operation",
    "name": "AuthDisableSessionMutation",
    "argumentDefinitions": (v0/*: any*/),
    "selections": (v1/*: any*/)
  },
  "params": {
    "operationKind": "mutation",
    "name": "AuthDisableSessionMutation",
    "id": null,
    "text": "mutation AuthDisableSessionMutation(\n  $input: UserDisableSessionInput!\n) {\n  disableSessions(input: $input) {\n    output {\n      id\n    }\n  }\n}\n",
    "metadata": {}
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '31df69ae2aa810cc5ef4c719d31fdbdf';
module.exports = node;
