export default function useForm(props = {}) {
  const getName = (event, data) => {
    if (data) return data.name
    if (event && event.target) return event.target.name

    return null
  }

  const getValue = (event, data) => {
    if (data) return data.value
    if (event && event.target) return event.target.value

    return ''
  }

  const handleBlur = (event, data) => {
    const name = getName(event, data)

    if (name && props.onBlur) props.onBlur({ name })
  }

  const handleChange = (event, data) => {
    const name = getName(event, data)

    if (name && props.onChange) {
      props.onChange({ name, value: getValue(event, data) })
    }
  }

  return { handleBlur, handleChange }
}
