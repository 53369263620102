/**
 * @flow
 * @relayHash 02625ef73c506341b5fb51ed9770ac98
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type CompanyContextQueryVariables = {||};
export type CompanyContextQueryResponse = {|
  +companies: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +code: number,
        +abbr: ?string,
      |}
    |}>
  |}
|};
export type CompanyContextQuery = {|
  variables: CompanyContextQueryVariables,
  response: CompanyContextQueryResponse,
|};
*/

/*
query CompanyContextQuery {
  companies(orderBy: [code], order: ASC, where: {isDisabled: {eq: "false"}}) {
    edges {
      node {
        id
        name
        code
        abbr
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
    {
      kind: 'LinkedField',
      alias: null,
      name: 'companies',
      storageKey:
        'companies(order:"ASC",orderBy:["code"],where:{"isDisabled":{"eq":"false"}})',
      args: [
        {
          kind: 'Literal',
          name: 'order',
          value: 'ASC',
        },
        {
          kind: 'Literal',
          name: 'orderBy',
          value: ['code'],
        },
        {
          kind: 'Literal',
          name: 'where',
          value: {
            isDisabled: {
              eq: 'false',
            },
          },
        },
      ],
      concreteType: 'CompanyConnection',
      plural: false,
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'edges',
          storageKey: null,
          args: null,
          concreteType: 'CompanyEdge',
          plural: true,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'node',
              storageKey: null,
              args: null,
              concreteType: 'Company',
              plural: false,
              selections: [
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'id',
                  args: null,
                  storageKey: null,
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'name',
                  args: null,
                  storageKey: null,
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'code',
                  args: null,
                  storageKey: null,
                },
                {
                  kind: 'ScalarField',
                  alias: null,
                  name: 'abbr',
                  args: null,
                  storageKey: null,
                },
              ],
            },
          ],
        },
      ],
    },
  ]
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'CompanyContextQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: [],
      selections: (v0 /*: any*/),
    },
    operation: {
      kind: 'Operation',
      name: 'CompanyContextQuery',
      argumentDefinitions: [],
      selections: (v0 /*: any*/),
    },
    params: {
      operationKind: 'query',
      name: 'CompanyContextQuery',
      id: null,
      text:
        'query CompanyContextQuery {\n  companies(orderBy: [code], order: ASC, where: {isDisabled: {eq: "false"}}) {\n    edges {\n      node {\n        id\n        name\n        code\n        abbr\n      }\n    }\n  }\n}\n',
      metadata: {},
    },
  }
})()
// prettier-ignore
;(node/*: any*/).hash = 'b5a6c52790efa0160347996a7299185b';
module.exports = node
