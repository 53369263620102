import swal from 'sweetalert'

const timer = 2000

const createMessage = message => {
  const span = document.createElement('span')
  span.innerHTML = message.replace(/\n/g, '<br />')
  return span
}

const success = (message = '') =>
  swal({
    title: 'Amber',
    content: createMessage(message),
    icon: 'success',
    button: { visible: false },
    timer,
    closeOnEsc: true,
  })

const error = (message = '') =>
  swal({
    title: 'Amber',
    content: createMessage(message),
    icon: 'error',
    closeOnEsc: true,
  })

const confirm = (message = '') =>
  swal({
    title: 'Amber',
    content: createMessage(message),
    icon: 'warning',
    buttons: ['Cancelar', 'Aceptar'],
  })

alert.success = success
alert.error = error
alert.confirm = confirm

export default {
  success,
  error,
  confirm,
}
