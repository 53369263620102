import {
  fetchQuery as relayFetchQuery,
  commitMutation as relayCommitMutation,
} from 'react-relay'
import { ConnectionHandler } from 'relay-runtime'
import environment from './environment'

export const fetchQuery = function(query, variables) {
  return relayFetchQuery(environment, query, variables)
}

// mutation: GraphQLTaggedNode,
// variables: {[name: string]: any},
// onCompleted?: ?(response: ?Object, errors: ?Array<Error>) => void,
// onError?: ?(error: Error) => void,
// optimisticResponse?: Object,
// optimisticUpdater?: ?(store: RecordSourceSelectorProxy) => void,
// updater?: ?(store: RecordSourceSelectorProxy, data: SelectorData) => void,
// configs?: Array<DeclarativeMutationConfig>,

export const commitMutation = function({
  mutation,
  variables,
  optimisticResponse,
  optimisticUpdater,
  updater,
  configs,
}) {
  return new Promise((resolve, reject) => {
    relayCommitMutation(environment, {
      mutation,
      variables,
      onCompleted: resolve,
      onError: reject,
      optimisticResponse,
      optimisticUpdater,
      updater,
      configs,
    })
  })
}

export const updater = ({ store, service, proxyId, connectionKey }) => {
  const payload = store.getRootField(service)
  const output = payload.getLinkedRecord('output')

  const proxy = store.get(proxyId)
  const connection = ConnectionHandler.getConnection(proxy, connectionKey)

  const edge = ConnectionHandler.createEdge(store, connection, output, 'edges')
  ConnectionHandler.insertEdgeAfter(connection, edge)
}
