import React, { useContext } from 'react'
import { Route } from 'react-router-dom'
import SessionContext from 'context/SessionContext'

export default function AuthRoute(props) {
  const session = useContext(SessionContext)

  if (props.can && session.can(props.can)) {
    return <Route {...props} />
  }

  return null
}
