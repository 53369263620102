import graphql from 'babel-plugin-relay/macro'
import { fetchQuery } from 'config/amber-relay'
import React from 'react'

export const PaymentTypeContext = React.createContext()

export const PaymentTypeConsumer = PaymentTypeContext.Consumer

export function PaymentTypeProvider(props) {
  const [loaded, setLoaded] = React.useState(false)
  const [paymentTypes, setPaymentTypes] = React.useState([])

  React.useEffect(() => {
    if (!loaded) load()
  })

  const load = (force = false) => {
    if (loaded && !force) return

    fetchQuery(graphql`
      query PaymentTypeContextQuery {
        paymentTypes(
          orderBy: [code]
          order: ASC
          where: { isDisabled: { eq: "false" } }
        ) {
          edges {
            node {
              ...PaymentTypeSelect_paymentType @relay(mask: false)
              ...PaymentTypeTable_paymentType @relay(mask: false)
            }
          }
        }
      }
    `)
      .then(({ paymentTypes }) => {
        setLoaded(true)
        setPaymentTypes(paymentTypes.edges.map(({ node }) => node))
      })
      .catch(err => console.error('PaymentTypeContext', err.message))
  }

  return (
    <PaymentTypeContext.Provider
      value={{ loaded, paymentTypes, loadPaymentTypes: load }}
    >
      {props.children}
    </PaymentTypeContext.Provider>
  )
}

export default PaymentTypeContext
