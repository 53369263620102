import { Environment, Network, RecordSource, Store } from 'relay-runtime'
import Api from './api'

const store = new Store(new RecordSource())

const network = Network.create((operation, variables) =>
  Api.graphql({
    query: operation.text,
    variables,
  })
    .then(response => {
      if (response.errors && response.errors.length > 0) {
        const error = response.errors[0]
        throw new Error(error.message)
      }

      return response
    })
    .catch(err => {
      if (err.code === 401) {
        throw err
      }

      if (err.message) throw new Error(err.message)

      throw new Error(`Error interno del servidor. ${err}`)
    })
)

const environment = new Environment({ network, store })

export default environment
