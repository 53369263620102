import React from 'react'

import { ClientCreateModal } from 'core/client'
import RoleCreateModal from 'core/role/pages/RoleCreateModal'

export const ModalContext = React.createContext()

export const ModalConsumer = ModalContext.Consumer

export function ModalProvider(props) {
  const [isClientCreateModalOpen, setClientCreateModalOpen] = React.useState(
    false
  )
  const [isRoleCreateModalOpen, setRoleCreateModalOpen] = React.useState(false)

  const openClientCreateModal = _ => setClientCreateModalOpen(true)
  const closeClientCreateModal = _ => setClientCreateModalOpen(false)

  const openRoleCreateModal = _ => setRoleCreateModalOpen(true)
  const closeRoleCreateModal = _ => setRoleCreateModalOpen(false)

  return (
    <ModalContext.Provider
      value={{
        isClientCreateModalOpen,
        openClientCreateModal,
        closeClientCreateModal,
        isRoleCreateModalOpen,
        openRoleCreateModal,
        closeRoleCreateModal,
      }}
    >
      {props.children}

      <ClientCreateModal />
      <RoleCreateModal />
    </ModalContext.Provider>
  )
}

export default ModalContext
