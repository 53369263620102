import React from 'react'
import graphql from 'babel-plugin-relay/macro'
import { fetchQuery } from 'config/amber-relay'

export const ProductContext = React.createContext()

export const ProductConsumer = ProductContext.Consumer

export function ProductProvider(props) {
  const [loaded, setLoaded] = React.useState(false)
  const [products, setProducts] = React.useState([])

  React.useEffect(() => {
    if (!loaded) load()
  })

  const load = (force = false) => {
    if (loaded && !force) return

    fetchQuery(graphql`
      query ProductContextQuery {
        products(orderBy: [code], order: ASC) {
          edges {
            node {
              ...ProductSelect_product @relay(mask: false)
            }
          }
        }
      }
    `)
      .then(({ products }) => {
        setLoaded(true)
        setProducts(products.edges.map(({ node }) => node))
      })
      .catch(err => console.error('ProductContext', err.message))
  }

  return (
    <ProductContext.Provider value={{ loaded, products, loadProducts: load }}>
      {props.children}
    </ProductContext.Provider>
  )
}

export default ProductContext
