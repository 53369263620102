import graphql from 'babel-plugin-relay/macro'
import { fetchQuery } from 'config/amber-relay'
import React from 'react'

export const CompanyContext = React.createContext()

export const CompanyConsumer = CompanyContext.Consumer

export function CompanyProvider(props) {
  const [loaded, setLoaded] = React.useState(false)
  const [companies, setCompanies] = React.useState([])

  React.useEffect(() => {
    if (!loaded) load()
  })

  const load = (force = false) => {
    if (loaded && !force) return

    fetchQuery(graphql`
      query CompanyContextQuery {
        companies(
          orderBy: [code]
          order: ASC
          where: { isDisabled: { eq: "false" } }
        ) {
          edges {
            node {
              ...CompanySelect_company @relay(mask: false)
              ...CompanyTable_company @relay(mask: false)
            }
          }
        }
      }
    `)
      .then(({ companies }) => {
        setLoaded(true)
        setCompanies(companies.edges.map(({ node }) => node))
      })
      .catch(err => console.error('CompanyContext', err.message))
  }

  return (
    <CompanyContext.Provider value={{ loaded, companies, loadCompanies: load }}>
      {props.children}
    </CompanyContext.Provider>
  )
}

export default CompanyContext
