import React from 'react'
import { QueryRenderer } from 'react-relay'
import { Segment } from 'semantic-ui-react'

import environment from 'config/environment'
import ErrorView from './ErrorView'
import Loading from './Loading'

export default function QueryRender({ query, variables, render, segment }) {
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      render={({ error, props }) => {
        if (error) {
          return <ErrorView error={error} />
        } else if (!props) {
          if (segment) {
            return (
              <Segment loading>
                <p>s</p>
                <p>s</p>
                <p>s</p>
              </Segment>
            )
          }

          return <Loading />
        }

        return render({ props })
      }}
      variables={variables}
    />
  )
}
