import React from 'react'
import { Redirect } from 'react-router-dom'

export default function ErrorView(props) {
  const { error } = props

  if (error) {
    if (error.code !== 401) {
      return (
        <div>
          <h3>
            {`Ops! Se ha producido un error, lo corregirémos de inmediato.`}
            <br />
            {`Por favor contacte con su administrador.`}
            <br />
            {`Info: ${error.message}`}
          </h3>
        </div>
      )
    }

    return <Redirect to='/' />
  }

  return <h2>Error desconocido</h2>
}
