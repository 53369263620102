import { logout as sessionLogout } from '../context/SessionContext'

export const serverUrl = ''

const version = 'Version - 0.1.0' //UPDATE THIS

const errorParsingJson = err => {
  console.log(`Error parsing json ${err}`)
}

const errorInResponse = (body, reject) => {
  if (body.code === 401 && body.message === 'Invalid session') {
    errorInvalidSession()
  } else if (body.message) {
    reject(body)
  } else {
    console.log(`Unknow error ${JSON.stringify(body)}`)
  }
}

const errorInvalidSession = _ => {
  alert('Sesión inválida, por favor ingrese nuevamente')
  sessionLogout()
}

const errorServerUnavailable = _ => {
  alert('Servidor no disponible. Por favor contacte a su administrador')
}

const errorUnknow = err => {
  if (err.message) {
    alert(err.message)
  }

  console.log(`Unknow error ${JSON.stringify(err)}`)
}

const POST = (service, body) =>
  new Promise((resolve, reject) => {
    const session = localStorage.getItem('amber-session')
    const sessionKey = session ? JSON.parse(session).session : null

    fetch(`${serverUrl}${service}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Accept: 'application/json',
        'x-ses': sessionKey,
      },
      body: JSON.stringify(body),
    })
      .then(response => {
        if (response.ok) {
          if (
            response.headers.get('Content-Type') ===
            'application/json; charset=utf-8'
          ) {
            return response
              .json()
              .then(resolve)
              .catch(errorParsingJson)
          }

          return resolve(response)
        }

        return response
          .json()
          .then(body => errorInResponse(body, reject))
          .catch(errorParsingJson)
      })
      .catch(err => {
        if (err.message === 'Failed to fetch') {
          errorServerUnavailable()
        } else {
          errorUnknow(err)
        }
      })
  })

const graphql = body => POST('/graphql', body)

const process = (_id, path, order) => POST(`/${path}/process/${_id}`, { order })

const logout = () => POST('/logouts')

const showPDF = data =>
  new Promise((resolve, reject) => {
    const doc = new Uint8Array(data.data)
    const blob = new Blob([doc], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    let filePath = url
    let a = document.createElement('A')
    a.href = filePath
    a.download = filePath.substr(filePath.lastIndexOf('/') + 1)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
    resolve(filePath)
  })

const printPDF = data =>
  new Promise((resolve, reject) => {
    const doc = new Uint8Array(data.data)
    const blob = new Blob([doc], { type: 'application/pdf' })
    const url = window.URL.createObjectURL(blob)
    let printWindow = window.open(url)
    printWindow.print()
    resolve(url)
  })

export default {
  serverUrl,
  POST,
  graphql,
  process,
  logout,
  version,
  showPDF,
  printPDF,
}
