import {
  CREATE_CLIENT,
  CREATE_EXPENSE,
  CREATE_INVOICE,
  CREATE_MATERIAL_REQUEST,
  CREATE_PAYMENT_ENTRY,
  CREATE_PURCHASE,
  CREATE_PURCHASE_QUOTATION,
  CREATE_PURCHASE_QUOTATION_REQUEST,
  CREATE_SALE,
  CREATE_SUBSCRIPTION,
  CREATE_TICKET,
  CREATE_WAREHOUSE_ENTRY_AJUSTMENT,
  CREATE_WAREHOUSE_ENTRY_CONSIGNMENT,
  CREATE_WAREHOUSE_ENTRY_TRASNFER,
} from 'config/permissions'
import SessionContext from 'context/SessionContext'
import React, { useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { Grid, Menu, Popup } from 'semantic-ui-react'

const items = [
  [
    ['Cliente', CREATE_CLIENT, '/client/create'],
    ['Suscripción', CREATE_SUBSCRIPTION, '/subscription/create'],
    ['Venta', CREATE_SALE, '/sale/create'],
    ['Factura', CREATE_INVOICE, '/invoice/create'],
  ],
  [
    ['Ticket', CREATE_TICKET, '/ticket/create'],
    ['Gasto', CREATE_EXPENSE, '/expense/create'],
    ['Transferencia', CREATE_PAYMENT_ENTRY, '/payment-entry/create'],
  ],
  [
    [
      'Solicitud de Material',
      CREATE_MATERIAL_REQUEST,
      '/material-request/create',
    ],
    [
      'Solicitud de Cotización',
      CREATE_PURCHASE_QUOTATION_REQUEST,
      '/purchase-quotation-request/create',
    ],
    [
      'Cotización de Proveedor',
      CREATE_PURCHASE_QUOTATION,
      '/purchase-quotation/create',
    ],
    ['Orden de Compra', CREATE_PURCHASE, '/purchase/create'],
  ],
  [
    [
      'Ajuste',
      CREATE_WAREHOUSE_ENTRY_AJUSTMENT,
      '/warehouse-entry/create-ajustment',
    ],
    [
      'Traslado',
      CREATE_WAREHOUSE_ENTRY_TRASNFER,
      '/warehouse-entry/create-transfer',
    ],
    [
      'Consignación',
      CREATE_WAREHOUSE_ENTRY_CONSIGNMENT,
      '/warehouse-entry/create-consignment',
    ],
  ],
]

function MenuItemCreate({ history }) {
  const session = useContext(SessionContext)

  const [isOpen, setOpen] = React.useState(false)

  const renderItem = (label, permission, path) =>
    session.can(permission) ? (
      <Menu.Item
        key={path}
        content={label}
        onClick={() => history.push(path)}
      />
    ) : null

  return (
    <Popup
      flowing
      hoverable
      trigger={<Menu.Item icon='add' onClick={_ => setOpen(!isOpen)} />}
      position='bottom right'
    >
      <Grid columns='equal'>
        <Grid.Column>
          <h2>Ventas</h2>
          <Menu text vertical>
            {items[0].map(([label, permission, path]) =>
              renderItem(label, permission, path)
            )}
          </Menu>
        </Grid.Column>

        <Grid.Column>
          <h2>-</h2>
          <Menu text vertical>
            {items[1].map(([label, permission, path]) =>
              renderItem(label, permission, path)
            )}
          </Menu>
        </Grid.Column>

        <Grid.Column>
          <h2>Compras</h2>
          <Menu text vertical>
            {items[2].map(([label, permission, path]) =>
              renderItem(label, permission, path)
            )}
          </Menu>
        </Grid.Column>

        <Grid.Column>
          <h2>Inventario</h2>
          <Menu text vertical>
            {items[3].map(([label, permission, path]) =>
              renderItem(label, permission, path)
            )}
          </Menu>
        </Grid.Column>
      </Grid>
    </Popup>
  )
}

export default withRouter(MenuItemCreate)
