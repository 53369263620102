import React from 'react'

export default function useForm(props = {}) {
  const { initialState = {}, validations = {} } = props

  const [values, setValues] = React.useState(initialState)
  const [errors, setErrors] = React.useState({})
  const [touched, setTouched] = React.useState({})

  const reset = (callback = () => {}) => {
    setValues(initialState)
    setErrors({})
    setTouched({})
    callback()
  }

  const validate = (name, value) => {
    if (validations[name]) {
      if (validations[name].required && !value) {
        return 'Requerido'
      }
    }

    return null
  }

  const handleSubmit = (onSubmit = () => {}) => () => {
    const errors = {}
    const touched = {}

    Object.keys(validations).forEach(field => {
      touched[field] = true

      if (validations[field].required && !values[field]) {
        errors[field] = 'Requerido'
      }
    })

    setErrors(errors)
    setTouched(touched)

    if (Object.keys(errors).length === 0) onSubmit()
  }

  const handleChange = ({ name, value, target } = {}) => {
    if (!name && !target) return

    const _name = name || target.name
    const _value = value || target.value

    setValues(values => ({ ...values, [_name]: _value }))
    setErrors(errors => ({ ...errors, [_name]: validate(_name, _value) }))
  }

  const handleBlur = ({ name } = {}) => {
    if (!name) return

    setErrors(errors => ({ ...errors, [name]: validate(name, values[name]) }))
    setTouched(touched => ({ ...touched, [name]: true }))
  }

  return {
    values,
    errors,
    touched,
    reset,
    handleSubmit,
    handleChange,
    handleBlur,
  }
}
