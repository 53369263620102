import React from 'react'
import ReactDOM from 'react-dom'

// Styles
import 'semantic-ui-css/semantic.min.css'
import 'react-day-picker/lib/style.css'
import 'assets/styles/amber.css'

// Globals
import './config/alert'

import App from './App'
import * as serviceWorker from './serviceWorker'

ReactDOM.render(<App />, document.getElementById('app'))

serviceWorker.unregister()
