import React from 'react'
import { Form as UIForm } from 'semantic-ui-react'

// Hooks
import useInput from 'hooks/useInput'

export default function TextArea(props) {
  const { handleBlur, handleChange } = useInput(props)

  return (
    <UIForm.TextArea
      name={props.name}
      label={props.label}
      placeholder={props.placeholder || props.label}
      disabled={props.disabled}
      error={props.touched && props.error}
      value={props.value || ''}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  )
}

TextArea.defaultProps = {
  label: null,
  placeholder: null,
  disabled: false,
  loading: false,
  transparent: false,
  error: false,
  touched: false,
  value: null,
  onBlur: () => {},
  onChange: () => {},
}
