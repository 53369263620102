import React from 'react'
import Auth from 'config/Auth'

export const SESSION_KEY = 'amber-session'

const SessionContext = React.createContext()

export const SessionConsumer = SessionContext.Consumer

export const logout = () => {
  localStorage.removeItem(SESSION_KEY)
  window.location.reload()
}

export class SessionProvider extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isAuth: false,
      loading: true,
    }
  }

  componentDidMount() {
    this.loadSession()
  }

  loadSession = () => {
    const session = localStorage.getItem(SESSION_KEY)
    if (session) {
      this.setState({ ...JSON.parse(session), isAuth: true, loading: false })
    } else {
      this.setState({ loading: false })
    }
  }

  login = ({ email, password, onError }) => {
    Auth.login(email, password)
      .then(session =>
        localStorage.setItem(SESSION_KEY, JSON.stringify(session))
      )
      .then(this.loadSession)
      .catch(onError)
  }

  logout = () => {
    const session = localStorage.getItem(SESSION_KEY)
    const sessinJson = JSON.parse(session)
    const sessionId = sessinJson.session
    const userId = sessinJson.user.id
     Auth.logout(sessionId, userId)
    localStorage.removeItem(SESSION_KEY)
    this.setState({ isAuth: false })
  }

  can = permission => {
    return this.state.permissions.find(_p => _p === permission) ? true : false
  }

  render() {
    return (
      <SessionContext.Provider
        value={{
          ...this.state,
          loadSession: this.loadSession,
          login: this.login,
          logout: this.logout,
          can: this.can,
        }}
      >
        {this.props.children}
      </SessionContext.Provider>
    )
  }
}

export default SessionContext
