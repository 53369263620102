/**
 * @flow
 * @relayHash de0e15f6d383e885e7d1725796a7f599
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClientNameQueryVariables = {|
  id: string
|};
export type ClientNameQueryResponse = {|
  +client: ?{|
    +name: string
  |}
|};
export type ClientNameQuery = {|
  variables: ClientNameQueryVariables,
  response: ClientNameQueryResponse,
|};
*/

/*
query ClientNameQuery(
  $id: ID!
) {
  client(id: $id) {
    name
    id
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'id',
        type: 'ID!',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'Variable',
        name: 'id',
        variableName: 'id',
      },
    ],
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null,
    }
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'ClientNameQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'client',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'Client',
          plural: false,
          selections: [(v2 /*: any*/)],
        },
      ],
    },
    operation: {
      kind: 'Operation',
      name: 'ClientNameQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'client',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'Client',
          plural: false,
          selections: [
            (v2 /*: any*/),
            {
              kind: 'ScalarField',
              alias: null,
              name: 'id',
              args: null,
              storageKey: null,
            },
          ],
        },
      ],
    },
    params: {
      operationKind: 'query',
      name: 'ClientNameQuery',
      id: null,
      text:
        'query ClientNameQuery(\n  $id: ID!\n) {\n  client(id: $id) {\n    name\n    id\n  }\n}\n',
      metadata: {},
    },
  }
})()
// prettier-ignore
;(node/*: any*/).hash = '1b917ef478d2985749c99449909b1994';
module.exports = node
