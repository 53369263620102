/**
 * @flow
 * @relayHash 58e1cecadb22a911f48907b6e4309d9f
 */

/* eslint-disable */

'use strict'

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type LocationWhere = {|
  and?: ?$ReadOnlyArray<?LocationWhere>,
  or?: ?$ReadOnlyArray<?LocationWhere>,
  id?: ?ConditionInputType,
  code?: ?ConditionInputType,
  name?: ?ConditionInputType,
  companyId?: ?ConditionInputType,
  receiptCompanyId?: ?ConditionInputType,
  locationId?: ?ConditionInputType,
  isGroup?: ?ConditionInputType,
  address?: ?ConditionInputType,
  isDisabled?: ?ConditionInputType,
  createdAt?: ?ConditionInputType,
  createdBy?: ?ConditionInputType,
|};
export type ConditionInputType = {|
  eq?: ?string,
  ne?: ?string,
  gt?: ?string,
  gte?: ?string,
  lt?: ?string,
  lte?: ?string,
  is?: ?string,
  not?: ?string,
  in?: ?$ReadOnlyArray<?string>,
  notIn?: ?$ReadOnlyArray<?string>,
  like?: ?string,
  notLike?: ?string,
  iLike?: ?string,
  insub?: ?string,
|};
export type LocationSelectQueryVariables = {|
  where?: ?LocationWhere
|};
export type LocationSelectQueryResponse = {|
  +locations: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +name: string,
        +location: ?{|
          +name: string,
          +location: ?{|
            +name: string
          |},
        |},
      |}
    |}>
  |}
|};
export type LocationSelectQuery = {|
  variables: LocationSelectQueryVariables,
  response: LocationSelectQueryResponse,
|};
*/

/*
query LocationSelectQuery(
  $where: LocationWhere
) {
  locations(where: $where, first: 10, orderBy: [name], order: ASC) {
    edges {
      node {
        id
        name
        location {
          name
          location {
            name
            id
          }
          id
        }
      }
    }
  }
}
*/

const node /*: ConcreteRequest*/ = (function() {
  var v0 = [
      {
        kind: 'LocalArgument',
        name: 'where',
        type: 'LocationWhere',
        defaultValue: null,
      },
    ],
    v1 = [
      {
        kind: 'Literal',
        name: 'first',
        value: 10,
      },
      {
        kind: 'Literal',
        name: 'order',
        value: 'ASC',
      },
      {
        kind: 'Literal',
        name: 'orderBy',
        value: ['name'],
      },
      {
        kind: 'Variable',
        name: 'where',
        variableName: 'where',
      },
    ],
    v2 = {
      kind: 'ScalarField',
      alias: null,
      name: 'id',
      args: null,
      storageKey: null,
    },
    v3 = {
      kind: 'ScalarField',
      alias: null,
      name: 'name',
      args: null,
      storageKey: null,
    }
  return {
    kind: 'Request',
    fragment: {
      kind: 'Fragment',
      name: 'LocationSelectQuery',
      type: 'Query',
      metadata: null,
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'locations',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'LocationConnection',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'edges',
              storageKey: null,
              args: null,
              concreteType: 'LocationEdge',
              plural: true,
              selections: [
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'node',
                  storageKey: null,
                  args: null,
                  concreteType: 'Location',
                  plural: false,
                  selections: [
                    (v2 /*: any*/),
                    (v3 /*: any*/),
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'location',
                      storageKey: null,
                      args: null,
                      concreteType: 'Location',
                      plural: false,
                      selections: [
                        (v3 /*: any*/),
                        {
                          kind: 'LinkedField',
                          alias: null,
                          name: 'location',
                          storageKey: null,
                          args: null,
                          concreteType: 'Location',
                          plural: false,
                          selections: [(v3 /*: any*/)],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    operation: {
      kind: 'Operation',
      name: 'LocationSelectQuery',
      argumentDefinitions: (v0 /*: any*/),
      selections: [
        {
          kind: 'LinkedField',
          alias: null,
          name: 'locations',
          storageKey: null,
          args: (v1 /*: any*/),
          concreteType: 'LocationConnection',
          plural: false,
          selections: [
            {
              kind: 'LinkedField',
              alias: null,
              name: 'edges',
              storageKey: null,
              args: null,
              concreteType: 'LocationEdge',
              plural: true,
              selections: [
                {
                  kind: 'LinkedField',
                  alias: null,
                  name: 'node',
                  storageKey: null,
                  args: null,
                  concreteType: 'Location',
                  plural: false,
                  selections: [
                    (v2 /*: any*/),
                    (v3 /*: any*/),
                    {
                      kind: 'LinkedField',
                      alias: null,
                      name: 'location',
                      storageKey: null,
                      args: null,
                      concreteType: 'Location',
                      plural: false,
                      selections: [
                        (v3 /*: any*/),
                        {
                          kind: 'LinkedField',
                          alias: null,
                          name: 'location',
                          storageKey: null,
                          args: null,
                          concreteType: 'Location',
                          plural: false,
                          selections: [(v3 /*: any*/), (v2 /*: any*/)],
                        },
                        (v2 /*: any*/),
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    params: {
      operationKind: 'query',
      name: 'LocationSelectQuery',
      id: null,
      text:
        'query LocationSelectQuery(\n  $where: LocationWhere\n) {\n  locations(where: $where, first: 10, orderBy: [name], order: ASC) {\n    edges {\n      node {\n        id\n        name\n        location {\n          name\n          location {\n            name\n            id\n          }\n          id\n        }\n      }\n    }\n  }\n}\n',
      metadata: {},
    },
  }
})()
// prettier-ignore
;(node/*: any*/).hash = '6c006e1de147c12278f8688df3d130eb';
module.exports = node
